import authAxios from '../../modules/shared/axios/authAxios';

const PATH = 'partners';

export default class OOHService {
  static async find(id) {
    const response = await authAxios.get(`/${PATH}/${id}`);
    return response.data;
  }

  static async create(data) {
    const body = {
        data,
      },
      response = await authAxios.post(`/${PATH}`, body);

    return response.data;
  }

  static async update(id, data) {
    const body = {
        id,
        data,
      },
      response = await authAxios.put(`/${PATH}/${id}`, body);

    return response.data;
  }

  static async archive(id) {
    const response = await authAxios.delete(`/${PATH}/${id}`);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
        filter,
        orderBy,
        limit,
        offset,
      },
      response = await authAxios.get(`/${PATH}`, {
        params,
      });
    return response.data;
  }

  static async import(data, id) {
    const body = {
        data,
      },
      response = await authAxios.post(`/${PATH}/import`, {
        body,
      });

    return response.data;
  }

  static async getFilterList() {
    /*
     * Const params = {
     *   query,
     *   limit,
     * };
     */

    const response = await authAxios.get('/filters/partnersDirectory', {
      // Params,
    });

    return response.data;
  }
}
