import authAxios from '~/modules/shared/axios/authAxios';

export type PartnerSummary = {
  logo: string;
  description: string;
  tier: string;
  slug: string;
  url: string;
};

export class PartnerService {
  static async getSummary(id: string): Promise<PartnerSummary> {
    const response = await authAxios.get(`/partners/${id}/summary`);
    return response.data;
  }
}
