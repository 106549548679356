import { postcodeValidator } from 'postcode-validator';
import yup from 'yup';

import country_data from '../../constants/contry_data';
import { i18n } from '../../i18n';
import {
  BooleanField,
  DateTimeField,
  DecimalField,
  EnumeratorField,
  IdField,
  IntegerField,
  RelationToManyField,
  RelationToOneField,
  ObjectField,
  StringField,
} from '../../modules/shared/fields';
import {
  latitudeModelOptions,
  longitudeModelOptions,
  streetViewURLModelOptions,
} from '~/utils/validators';

function label(name: string): string {
  return i18n(`entities.storefront.fields.${name}`);
}

function availabilityLabel(name: string): string {
  return i18n(`entities.availability.fields.${name}`);
}

const availabilityFields = {
  id: new IdField('id', availabilityLabel('id')),
  price: new IntegerField('price', availabilityLabel('price'), {
    required: false,
    decimals: 2,
    defaultValue: null,
    allowNegative: false,
    valuePrefix: '$',
    dependentFieldSettings: [
      {
        available: (available: number, schema: yup.NumberSchema) =>
          available ? schema.min(0.01) : schema,
      },
    ],
  }),
  available: new BooleanField('available', availabilityLabel('available'), {
    required: false,
  }),
  date: new DateTimeField('date', availabilityLabel('date'), {
    required: false,
  }),
  updatedAt: new DateTimeField('updatedAt', availabilityLabel('updatedAt'), {
    required: false,
  }),
  purchased: new BooleanField('purchased', availabilityLabel('purchased'), {
    required: false,
    yesLabel: availabilityLabel('common.yes'),
    noLabel: availabilityLabel('common.no'),
  }),
};

const storefrontFeatureFields = {
  id: new IdField('storefrontFeature.id', label('extensionCostPerSqftId.label')),
  extensionCostPerSqft: new IntegerField(
    'storefrontFeature.extensionCostPerSqft',
    label('extensionCostPerSqft.label'),
    {
      allowNegative: false,
      decimals: 2,
      defaultValue: null,
    },
  ),
  obstructions: new EnumeratorField('storefrontFeature.obstructions', label('obstructions.label'), [
    {
      id: 'unObstructed',
      label: label('obstructions.unObstructed'),
      literalValues: [
        'yes',
        'y',
        '1',
        'UnObstructed',
        'unObstructed',
        'unobstructed',
        'UNOBSTRUCTED',
      ],
    },
    {
      id: 'obstructed',
      label: label('obstructions.obstructed'),
      literalValues: ['no', 'No', 'n', '0', 'Obstructed', 'obstructed', 'OBSTRUCTED'],
    },
    {
      id: 'unknown',
      label: 'Unknown',
      literalValues: ['unknown', 'Unknown', 'UNKNOWN', 'u', 'none'],
    },
  ]),
  condition: new EnumeratorField('storefrontFeature.condition', label('condition.label'), [
    {
      id: 'good',
      label: label('condition.good'),
      literalValues: ['yes', 'y', '1', 'GOOD', 'Good', 'good'],
    },
    {
      id: 'bad',
      label: label('condition.bad'),
      literalValues: ['no', 'n', '0', 'BAD', 'Bad', 'bad'],
    },
    {
      id: 'unknown',
      label: 'Unknown',
      literalValues: ['unknown', 'Unknown', 'u', 'UNKNOWN', 'none'],
    },
  ]),
  prominence: new EnumeratorField('storefrontFeature.prominence', label('prominence.label'), [
    {
      id: 'prominent',
      label: label('prominence.prominent'),
      literalValues: ['yes', 'y', '1', 'Prominent', 'prominent', 'PROMINENT'],
    },
    {
      id: 'crowded',
      label: label('prominence.crowded'),
      literalValues: ['no', 'n', '0', 'Crowded', 'crowded', 'CROWDED'],
    },
    {
      id: 'unknown',
      label: 'Unknown',
      literalValues: ['unknown', 'Unknown', 'UNKNOWN', 'u', 'none'],
    },
  ]),
};
const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
  }),
  unitNumber: new StringField('unitNumber', label('unitNumber'), {
    type: 'string',
  }),
  frameId: new StringField('frameId', 'Geopath Frame ID', {
    allowNegative: false,
    defaultValue: null,
  }),
  geopathId: new IntegerField('geopathId', label('geopathId'), {
    type: 'string',
  }),
  description: new StringField('description', label('description'), {
    type: 'string',
  }),
  physicalAddress: new StringField('physicalAddress', label('physicalAddress'), {
    type: 'string',
  }),
  city: new StringField('city', label('city'), {
    type: 'string',
  }),
  action: new StringField('Action', label('Action'), {
    type: 'string',
  }),
  state: new StringField('state', label('state'), {
    type: 'string',
  }),
  zip: new StringField('zip', label('zip'), {
    type: 'string',
    // Tests zip within spec validator, once negative throws an error
    tester(zip: string) {
      let isValid = true;
      if (!zip) {
        return isValid;
      }

      const {
        parent: { country },
      } = this;
      if (!country) {
        return isValid;
      }
      isValid = postcodeValidator(zip, country);

      return isValid;
    },
    errorMessage: 'Zip is not correct for current country!',
  }),
  country: new EnumeratorField(
    'country',
    label('country'),
    country_data.map((country) => ({
      id: country.ISO,
      label: country.Country,
    })),
    {
      dependentFieldSettings: [
        {
          zip: (zip: string, schema: yup.StringSchema) =>
            zip ? schema.required('Please, define country too!') : schema,
        },
      ],
    },
  ),
  latitude: new StringField('latitude', 'Latitude', latitudeModelOptions),
  longitude: new StringField('longitude', 'Longitude', longitudeModelOptions),
  streetViewURL: new StringField(
    'streetViewURL',
    'Approachable Street View',
    streetViewURLModelOptions,
  ),
  inventoryTypes: new RelationToManyField('inventoryTypes', label('inventoryTypes'), {
    delimiter: ',',
    property: 'name',
  }),
  artworkFormats: new EnumeratorField(
    'artworkFormats',
    label('artworkFormats.label'),
    [
      {
        id: 'AI',
        label: 'AI',
        literalValues: ['ai'],
      },
      {
        id: 'AVI',
        label: 'AVI',
        literalValues: ['avi'],
      },
      {
        id: 'BMP',
        label: 'BMP',
        literalValues: ['bmp'],
      },
      {
        id: 'EPS',
        label: 'EPS',
        literalValues: ['eps'],
      },
      {
        id: 'GIF',
        label: 'GIF',
        literalValues: ['gif'],
      },
      {
        id: 'IND',
        label: 'IND',
        literalValues: ['ind'],
      },
      {
        id: 'JPG',
        label: 'JPG',
        literalValues: ['jpg', 'jpeg'],
      },
      {
        id: 'MOV',
        label: 'MOV',
        literalValues: ['mov'],
      },
      {
        id: 'MP4',
        label: 'MP4',
        literalValues: ['mp4'],
      },
      {
        id: 'PDF',
        label: 'PDF',
        literalValues: ['pdf'],
      },
      {
        id: 'PNG',
        label: 'PNG',
        literalValues: ['png'],
      },
      {
        id: 'PSD',
        label: 'PSD',
        literalValues: ['psd'],
      },
      {
        id: 'TIFF',
        label: 'TIFF',
        literalValues: ['tiff'],
      },
      {
        id: 'WMV',
        label: 'WMV',
        literalValues: ['wmv'],
      },
    ],
    {
      delimiter: ',',
    },
  ),
  facing: new EnumeratorField(
    'facing',
    label('facing.label'),
    [
      {
        id: 'North',
        label: label('facing.N'),
        literalValues: ['n', 'north'],
      },
      {
        id: 'NorthWest',
        label: label('facing.NW'),
        literalValues: ['nw', 'northwest'],
      },
      {
        id: 'West',
        label: label('facing.W'),
        literalValues: ['w', 'west'],
      },
      {
        id: 'SouthWest',
        label: label('facing.SW'),
        literalValues: ['sw', 'southwest'],
      },
      {
        id: 'South',
        label: label('facing.S'),
        literalValues: ['s', 'south'],
      },
      {
        id: 'SouthEast',
        label: label('facing.SE'),
        literalValues: ['se', 'southeast'],
      },
      {
        id: 'East',
        label: label('facing.E'),
        literalValues: ['e', 'east'],
      },
      {
        id: 'NorthEast',
        label: label('facing.NE'),
        literalValues: ['ne', 'northeast'],
      },
    ],
    {
      required: false,
    },
  ),
  position: new EnumeratorField(
    'position',
    label('position.label'),
    [
      {
        id: 'lhr',
        label: label('position.lhr'),
        literalValues: ['lhr', 'left', 'l'],
      },
      {
        id: 'rhr',
        label: label('position.rhr'),
        literalValues: ['rhr', 'right', 'r'],
      },

      {
        id: 'center',
        label: label('position.center'),
        literalValues: ['c', 'center'],
      },
      {
        id: 'parallel',
        label: label('position.parallel'),
        literalValues: ['Parallel', 'p'],
      },
      {
        id: 'head-on',
        label: label('position.head-on'),
        literalValues: ['head-on', 'h'],
      },
      {
        id: 'various',
        label: label('position.various'),
        literalValues: ['Various', 'v'],
      },
    ],
    {
      required: false,
    },
  ),
  weeklyAdultImpressionsCount: new IntegerField(
    'weeklyAdultImpressionsCount',
    label('weeklyAdultImpressionsCount'),
    {
      allowNegative: false,
      defaultValue: null,
    },
  ),
  uniqueAdultAdReachedCount: new IntegerField(
    'uniqueAdultAdReachedCount',
    label('uniqueAdultAdReachedCount'),
    {
      allowNegative: false,
      decimals: 2,
      defaultValue: null,
    },
  ),
  advertisersCount: new IntegerField('advertisersCount', label('advertisersCount'), {
    allowNegative: false,
    defaultValue: null,
  }),
  grp: new StringField('grp', label('grp'), {
    type: 'string',
  }),
  spotLength: new IntegerField('spotLength', label('spotLength'), {
    valueSuffix: ' sec',
    allowNegative: false,
    defaultValue: null,
  }),
  loopLength: new IntegerField('loopLength', label('loopLength'), {
    valueSuffix: ' sec',
    allowNegative: false,
    defaultValue: null,
  }),
  averageAdultFrequencyReach: new IntegerField(
    'averageAdultFrequencyReach',
    label('averageAdultFrequencyReach'),
    {
      allowNegative: false,
      decimals: 2,
      defaultValue: null,
    },
  ),
  sov: new IntegerField('sov', label('sov'), {
    allowNegative: false,
    decimals: 2,
    min: 0,
    max: 100,
    matches: new RegExp('^([0-9]{1,2}){1}(.[0-9]{1,2})?$'),
    defaultValue: null,
  }),
  fourWeekRateCardCost: new IntegerField('fourWeekRateCardCost', label('fourWeekRateCardCost'), {
    allowNegative: false,
    decimals: 2,
    defaultValue: null,
  }),
  fourWeekRateNegotiatedCost: new IntegerField(
    'fourWeekRateNegotiatedCost',
    label('fourWeekRateNegotiatedCost'),
    {
      allowNegative: false,
      decimals: 2,
      defaultValue: null,
    },
  ),
  productionCost: new IntegerField('productionCost', label('productionCost'), {
    allowNegative: false,
    decimals: 2,
    defaultValue: null,
  }),
  installCost: new IntegerField('installCost', label('installCost'), {
    allowNegative: false,
    decimals: 2,
    defaultValue: null,
  }),
  vinylSize: new StringField('vinylSize', label('vinylSize'), {
    /*
     * RegExp for panel size string [num]*[num] zerosafe
     * matches: /([1-9]\d*)\*([1-9]\d*)/g,
     * errorMessage: `Please define "${label('vinylSize')}" as [width]*[height]`,
     */
  }),
  panelSize: new StringField('panelSize', label('panelSize'), {
    /*
     * RegExp for panel size string [num]*[num] zerosafe
     * matches: /([1-9]\d*)\*([1-9]\d*)/g,
     * errorMessage: `Please define "${label('panelSize')}" as [width]*[height]`,
     */
  }),
  shippingAddress: new StringField('shippingAddress', label('shippingAddress'), {}),
  illuminated: new BooleanField('illuminated', label('illuminated.label'), {
    noLabel: i18n('illuminated.no'),
    yesLabel: i18n('illuminated.yes'),
    required: false,
  }),
  creativeExtension: new EnumeratorField('creativeExtension', label('creativeExtension.label'), [
    {
      id: 'yes',
      label: label('creativeExtension.yes'),
      literalValues: ['yes', 'y'],
    },
    {
      id: 'no',
      label: label('creativeExtension.no'),
      literalValues: ['no', 'n'],
    },
    {
      id: 'unknown',
      label: label('creativeExtension.unknown'),
      literalValues: ['unknown', 'u', 'none'],
    },
  ]),
  staticImageAd: new BooleanField('staticImageAd', label('staticImageAd.label'), {
    noLabel: i18n('staticImageAd.no'),
    yesLabel: i18n('staticImageAd.yes'),
    required: false,
  }),
  motionGraphicAd: new BooleanField('motionGraphicAd', label('motionGraphicAd.label'), {
    noLabel: i18n('motionGraphicAd.no'),
    yesLabel: i18n('motionGraphicAd.yes'),
    required: false,
  }),
  audioAd: new BooleanField('audioAd', label('audioAd.label'), {
    noLabel: i18n('audioAd.no'),
    yesLabel: i18n('audioAd.yes'),
    required: false,
  }),
  dimensions: new StringField('dimensions', 'Dimensions', {}),
  lengthLimit: new IntegerField('lengthLimit', label('lengthLimit'), {
    allowNegative: false,
    decimals: 2,
    defaultValue: null,
  }),
  noOfDaysMaterialIsDuePrior: new IntegerField(
    'noOfDaysMaterialIsDuePrior',
    label('noOfDaysMaterialIsDuePrior'),
    {
      defaultValue: null,
    },
  ),
  noOfDaysArtworkIsDuePrior: new IntegerField(
    'noOfDaysArtworkIsDuePrior',
    label('noOfDaysArtworkIsDuePrior'),
    {
      defaultValue: null,
    },
  ),
  fileSizeLimitMB: new IntegerField('fileSizeLimitMB', label('fileSizeLimitMB'), {
    allowNegative: false,
    decimals: 2,
    defaultValue: null,
  }),
  categoryRestrictions: new StringField('categoryRestrictions', label('categoryRestrictions'), {}),
  notes: new StringField('notes', 'Additional Notes', {}),

  url: new StringField('url', 'URL', {}),
  test: new StringField('test', label('test'), {}),
  hoo: new StringField('hoo', label('hoo'), {}),
  timezone: new StringField('timezone', label('timezone'), {}),
  timezoneName: new StringField('timezoneName', label('timezoneName'), {}),
  content: new RelationToManyField('content', label('content'), {}),
  customerProfiles: new RelationToManyField('customerProfiles', label('customerProfiles'), {}),
  device: new RelationToManyField('device', label('device'), {}),
  deviceSlug: new RelationToManyField('deviceSlug', 'Device Slug', {}),
  currentBalance: new DecimalField('currentBalance', 'Current Balance', {}),
  totalRevenue: new DecimalField('totalRevenue', 'Total Revenue', {}),
  tags: new RelationToManyField('tags', label('tags'), {}),
  users: new RelationToManyField('users', label('users'), {}),
  owner: new RelationToOneField('owner', label('owner'), {}),
  techsupport: new RelationToManyField('techsupport', label('techsupport'), {}),
  organizationType: new RelationToOneField('organizationType', label('organizationType'), {}),
  organizationCategory: new RelationToManyField(
    'organizationCategory',
    label('organizationCategory'),
    {},
  ),
  transactions: new RelationToManyField('transactions', label('transactions'), {}),
  status: new RelationToOneField('status', label('status'), {}),
  //  AnalyticsEvents: new RelationToManyField('analyticsEvents', label('analyticsEvents'), {}),
  organizationSector: new RelationToOneField('organizationSector', label('organizationSector'), {}),
  partnerCPMAsk: new StringField('partnerCPMAsk', 'CPM', {}),
  partnerVenueType: new StringField('partnerVenueType', 'Type', {}),
  gmapsUserRatingsTotal: new StringField('gmapsUserRatingsTotal', 'Rating Count', {}),
  gmapsRating: new StringField('gmapsRating', 'Rating Level', {}),
  gmapsPriceLevel: new StringField('gmapsPriceLevel', 'Price Level', {}),
  availability: new RelationToManyField('availability', label('availability'), {
    relatedModel: availabilityFields,
  }),
  storefrontFeature: new ObjectField(
    'storefrontFeature',
    label('extensionCostPerSqft.label'),
    storefrontFeatureFields,
    {
      exportableFields: ['storefrontFeature.extensionCostPerSqft'],
    },
  ),
  nextAvailableDate: new StringField('nextAvailableDate', label('nextAvailableDate'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
};

export { fields, storefrontFeatureFields };
